:root {
  overflow: hidden;
  height: 100%;
  --w3m-color-fg-1: rgb(228,231,231);
  --w3m-color-fg-2: rgb(148,158,158);
  --w3m-color-fg-3: rgb(110,119,119);
  --w3m-color-bg-1: rgb(20,20,20);
  --w3m-color-bg-2: rgb(39,42,42);
  --w3m-color-bg-3: rgb(59,64,64);
  --w3m-color-overlay: rgba(255,255,255,0.1);
  --w3m-accent-color: #3396FF;
  --w3m-accent-fill-color: #FFFFFF;
  --w3m-z-index: 89;
  --w3m-background-color: #996BFF;
  --w3m-background-border-radius: 8px;
  --w3m-container-border-radius: 30px;
  --w3m-wallet-icon-border-radius: 15px;
  --w3m-wallet-icon-large-border-radius: 30px;
  --w3m-wallet-icon-small-border-radius: 7px;
  --w3m-input-border-radius: 28px;
  --w3m-button-border-radius: 10px;
  --w3m-notification-border-radius: 36px;
  --w3m-secondary-button-border-radius: 28px;
  --w3m-icon-button-border-radius: 50%;
  --w3m-button-hover-highlight-border-radius: 10px;
  --w3m-text-big-bold-size: 20px;
  --w3m-text-big-bold-weight: 600;
  --w3m-text-big-bold-line-height: 24px;
  --w3m-text-big-bold-letter-spacing: -0.03em;
  --w3m-text-big-bold-text-transform: none;
  --w3m-text-xsmall-bold-size: 10px;
  --w3m-text-xsmall-bold-weight: 700;
  --w3m-text-xsmall-bold-line-height: 12px;
  --w3m-text-xsmall-bold-letter-spacing: 0.02em;
  --w3m-text-xsmall-bold-text-transform: uppercase;
  --w3m-text-xsmall-regular-size: 12px;
  --w3m-text-xsmall-regular-weight: 600;
  --w3m-text-xsmall-regular-line-height: 14px;
  --w3m-text-xsmall-regular-letter-spacing: -0.03em;
  --w3m-text-xsmall-regular-text-transform: none;
  --w3m-text-small-thin-size: 14px;
  --w3m-text-small-thin-weight: 500;
  --w3m-text-small-thin-line-height: 16px;
  --w3m-text-small-thin-letter-spacing: -0.03em;
  --w3m-text-small-thin-text-transform: none;
  --w3m-text-small-regular-size: 14px;
  --w3m-text-small-regular-weight: 600;
  --w3m-text-small-regular-line-height: 16px;
  --w3m-text-small-regular-letter-spacing: -0.03em;
  --w3m-text-small-regular-text-transform: none;
  --w3m-text-medium-regular-size: 16px;
  --w3m-text-medium-regular-weight: 600;
  --w3m-text-medium-regular-line-height: 20px;
  --w3m-text-medium-regular-letter-spacing: -0.03em;
  --w3m-text-medium-regular-text-transform: none;
  --w3m-font-family: Questrial, sans-serif;
  --w3m-font-feature-settings: 'tnum' on, 'lnum' on, 'case' on;
  --w3m-success-color: rgb(38,181,98);
  --w3m-error-color: rgb(242, 90, 103);
  --w3m-overlay-background-color: rgba(101,31,255,0.1);
  --w3m-overlay-backdrop-filter: blur(5px);
  --w3m-background-image-url: none;
  --swiper-theme-color: #007aff;
}


body, footer {
  overflow: hidden;
}

body {
  background-color: #000105;
  color: #fff;
  font-family: Questrial,sans-serif;
}

*, :after, :before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}