
.button {
  height: 40px;
  min-height: 40px;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 14px;

  font-family: 'Helvetica Neue Cyr', sans-serif;
  font-weight: 700;
  font-size: 18px;

  padding-inline: 16px;

  position: relative;

  overflow: hidden;

  @media (max-width: 1760px) {
    font-size: 14px;
    min-height: 35px;
    height: 35px;

    padding-inline: 12.5px;
  }

  @media (max-width: 768px) {
    font-size: 16px;
  }

  &:hover {
    &.two {
      .bgTwo {
        opacity: 1 !important;
      }
    }
  }

  .bg {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    pointer-events: none;

    z-index: -1;

    background-size: 100%;
    background-position: center;

    background-repeat: repeat-x;

    transition: background-image 0.5s ease;
  }

  &.one {
    border-radius: 250px;

    color: #fff;

    .bg {
      background-color: #222;
    }
  }

  &.two {
    border-radius: 212px;

    color: #0f0f0f;

    .bg {
      background-image: linear-gradient(
        118deg,
        #fff 0%,
        #ff82e0 48.21%,
        #996bff 100%
      );
    }

    .bgTwo {
      background: linear-gradient(118deg, #fff 0%, #ff82e0 8.21%, #996bff 100%);

      transition: opacity 0.5s ease;
      opacity: 0;
    }

    .bg.disabled {
      background-image: linear-gradient(
        118deg,
        #fff 0%,
        #b6b6b6 48.21%,
        #5e5e5e 100%
      );
    }

    .bgTwo.disabled {
      background: linear-gradient(118deg, #fff 0%, #b6b6b6 8.21%, #5e5e5e 100%);
    }
  }

  .icon {
    width: 18px;
    height: 18px;

    @media (max-width: 1760px) {
      width: 14px;
      height: 14px;
    }

    svg,
    img {
      width: 100%;
      height: 100%;
    }

    img {
      object-fit: contain;
      object-position: center;
    }
  }
}
